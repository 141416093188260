import React from 'react';
import classnames from 'classnames';

import Layout from '../components/layout';
import SEO from '../components/seo';
import BandAid from '../components/BandAid';
import ValueBlock from '../components/ValueBlock';
import People from '../components/svg_icons/People';
import Empathy from '../components/svg_icons/Empathy';
import Process from '../components/svg_icons/Process';
import Speed from '../components/svg_icons/Speed';
import ValuesPlusImage from '../components/ValuesPlusImage';
import phone from '../images/phone.png';
import heroPlus from '../images/hero_plus.png';
import rboxLogo from '../images/rbox-logo.png';
import dinoLogo from '../images/dino-logo.png';
import rboxPhone from '../images/rbox-phone.png';
import stripeWork from '../images/stripe_work.png';
import dotsWork from '../images/dots_work.png';

import Auth from '@aws-amplify/auth';
import Analytics from '@aws-amplify/analytics';
import LetsTalkForm from '../components/LetsTalkForm';

// import awsconfig from '../aws-exports';
//
// // retrieve temporary AWS credentials and sign requests
// Auth.configure(awsconfig);
// // send analytics events to Amazon Pinpoint
// Analytics.configure(awsconfig);

class IndexPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            startHeroAnimation: false
        };
    }

    startAnimation = (whichAnimation) => {
        console.log('animate called');
        this.setState({
            [whichAnimation]: true
        });
    }

    startHeroAnimation = () => {
        if (this.heroPhoneImage.complete && this.heroPlusImage.complete) {
            this.startAnimation('startHeroAnimation');
        }
    }

    componentDidMount() {
        this.startHeroAnimation();
    }

    render() {
        const heroPhoneImageClasses = classnames('section--hero__phone-image', {
            'section--hero__phone-image--animate': this.state.startHeroAnimation
        });

        const heroPlusImageClasses = classnames('section--hero__plus-image', {
            'section--hero__plus-image--animate': this.state.startHeroAnimation
        })

        return (
            <Layout>
                <SEO
                    title="PlusMinus Labs"
                    keywords={[
                        `plusminus labs`,
                        `services`,
                        `design`,
                        `products`,
                        `mobile apps`,
                        `web design`,
                        `web apps`
                    ]}
                />
                <section className="section section--hero">
                    <div className="container container--plus section--hero__container">
                        <img
                            ref={(n) => this.heroPlusImage = n}
                            onLoad={this.startHeroAnimation}
                            src={heroPlus}
                            className={heroPlusImageClasses}
                            alt="phone"
                        />
                        <span className="section__text section__text--hero">
                            World class product team bringing new ideas to life
                            <span className="blue-underline blue-underline--hero" />
                        </span>
                        <img
                            ref={(n) => this.heroPhoneImage = n}
                            onLoad={this.startHeroAnimation}
                            src={phone}
                            className={heroPhoneImageClasses}
                            alt="phone"
                        />
                    </div>
                </section>
                <section
                    id="values"
                    className="section section--dark section--values"
                >
                    <BandAid className="text-plus-red-light hidden sm:block" />
                    <div className="container container--plus flex flex-col sm:items-center sm:justify-center">
                        <span className="section__text relative">
                            What we value most
                            <span className="blue-underline blue-underline--values" />
                        </span>
                        <div className="flex flex-col relative">
                            <ValuesPlusImage />
                            <div className="flex-col sm:flex sm:flex-row sm:justify-between sm:mb-16">
                                <ValueBlock
                                    right={false}
                                    className="mb-12 sm:mb-0 sm:w-4/12"
                                    icon={Empathy}
                                    description="We care deeply about what solves our customer’s problem"
                                    title="Empathy"
                                />
                                <ValueBlock
                                    right={true}
                                    className="mb-12 sm:mb-0 sm:w-4/12"
                                    icon={People}
                                    description="We are a close-knit team that has worked together for better part of a decade"
                                    title="People"
                                />
                            </div>
                            <div className="flex-col sm:flex sm:flex-row sm:justify-between">
                                <ValueBlock
                                    right={false}
                                    className="mb-12 sm:mb-0 sm:w-4/12"
                                    icon={Process}
                                    description="We have perfected the art & sequence of building complex products well"
                                    title="Process"
                                />
                                <ValueBlock
                                    right={true}
                                    className="sm:w-4/12"
                                    icon={Speed}
                                    description="We swear by our scope & timelines, and deliver products on time"
                                    title="Speed"
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <section id="work" className="section section--work">
                    <BandAid className="text-plus-blue-light" />
                    <div className="container container--plus flex flex-col sm:items-center sm:justify-center">
                        <span className="section__text relative">
                            Work we are proud of
                            <span className="blue-underline blue-underline--work" />
                        </span>
                        <div className="flex-col flex sm:flex-row w-full ">
                            <div className="work-card-container work-card-container--rbox">
                                <a
                                    href="https://recruiterbox.com"
                                    target="_blank"
                                    className="work-card work-card--rbox"
                                >
                                    <div className="flex items-center mb-4">
                                        <div className="work-card__logo">
                                            <img src={rboxLogo} />
                                        </div>
                                        <div className="work-card__brand-name">
                                            Recruiterbox
                                        </div>
                                    </div>
                                    <div className="work-card__brand-text">
                                        Helping over 3000 companies hire faster and
                                        better. Daily.
                                    </div>
                                    <img
                                        src={rboxPhone}
                                        className="rbox-phone-image"
                                    />
                                </a>
                                <img
                                    src={stripeWork}
                                    className="work-stripe-image hidden sm:block"
                                />
                            </div>
                            <div className="work-card-container work-card-container--dino">
                                <a
                                    href="https://dino.xyz"
                                    target="_blank"
                                    className="work-card work-card--dino"
                                >
                                    <div className="flex items-center mb-4">
                                        <div className="work-card__logo">
                                            <img src={dinoLogo} />
                                        </div>
                                        <div className="work-card__brand-name">
                                            Dino
                                        </div>
                                    </div>
                                    <div className="work-card__brand-text">
                                        Build great teams around open & honest
                                        conversations!
                                    </div>
                                </a>
                                <img
                                    src={dotsWork}
                                    className="work-dots-image hidden sm:block"
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <section
                    id="contact"
                    className="section section--dark section--contact"
                >
                    <BandAid className="text-plus-purple-light" />
                    <div className="container container--plus flex flex-col items-center justify-center">
                        <span className="section__text relative">
                            What can we build for you?
                            <span className="blue-underline blue-underline--contact" />
                        </span>
                        <LetsTalkForm />
                    </div>
                </section>
                <section className="section section--copyright">
                    <div className="container container--plus flex flex-col items-center justify-center">
                        <span className="mb-6">© PlusMinus Labs 2019</span>
                        <span className="text-plus-grey">
                            San Francisco | Austin | Bengaluru
                        </span>
                    </div>
                </section>
            </Layout>
        );
    }
}

export default IndexPage;
