import React, { Fragment } from "react"
import ajax from '../utils/ajax';
import classnames from 'classnames';

function emailIsValid (email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

const initialState = {
    isSuccess: false,
    invalidEmail: false
};

class LetsTalkForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = initialState;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const email = e.currentTarget.elements['email'].value;

        this.setState(initialState);

        if(!emailIsValid(email)) {
            return this.setState({
                invalidEmail: true
            });
        }

        this.setState({
            isLoading: true
        });

        ajax.ajax({
            url: `https://script.google.com/a/pluslabs.co/macros/s/AKfycbxqsA0ogqyNOBwTyI3DJN7sFgXv0LqFIi3rZW3BgrHTPD3jGZY/exec`,
            type: 'GET',
            data: {
                email
            },
            success: (response) => {
                this.setState({
                    isSuccess: response && response.indexOf('success') >= 0,
                    isLoading: false
                });

                setTimeout(() => {
                    this.setState(initialState);
                }, 4000)
            }
        });
    }

    render() {
        if (this.state.isSuccess) {
            return (<div className="text-xl">
                Thanks! We'll reach out to you as soon as possible.
            </div>);
        }

        return (
            <form className="flex flex-col sm:items-center sm:flex-row shadow w-full sm:w-1/2 rounded" onSubmit={this.handleSubmit}>
                <div className="input-wrapper">
                    <input placeholder="Your email address" name="email" type="email" required className="email-input"/>
                </div>
                <button className="lets-talk whitespace-no-wrap" disabled={this.state.isLoading}>
                    {
                        this.state.isLoading ?
                            (
                                <svg width="20px" height="20px" viewBox="0 0 100 100"
                                     preserveAspectRatio="xMidYMid" className="lds-double-ring"
                                     style={{"background": "none"}}>
                                    <circle cx="50" cy="50" fill="none"
                                            stroke-linecap="round" r="46" stroke-width="6" stroke="#fff"
                                            stroke-dasharray="60.415927 60.415927"
                                            transform="rotate(216.665 50 50)">
                                        <animateTransform attributeName="transform" type="rotate" calcMode="linear"
                                                          values="0 50 50;360 50 50"
                                                          keyTimes="0;1" dur="0.6s" begin="0s"
                                                          repeatCount="indefinite"/>
                                    </circle>
                                    <circle cx="50" cy="50" fill="none" stroke-linecap="round" r="30"
                                            stroke-width="6" stroke="#fff" stroke-dasharray="43.561945 43.561945"
                                            stroke-dashoffset="23.561944901923447" transform="rotate(-216.665 50 50)">
                                        <animateTransform attributeName="transform" type="rotate" calcMode="linear"
                                                          values="0 50 50;-360 50 50"
                                                          keyTimes="0;1" dur="0.6s" begin="0s"
                                                          repeatCount="indefinite"/>
                                    </circle>
                                </svg>
                            ) : (
                                <Fragment>
                                    Let's talk
                                    <svg width="14" height="14" className="ml-2"><path d="M134.821 25.167l-4.41-4.411a.833.833 0 1 1 1.178-1.179l5.834 5.834a.833.833 0 0 1 0 1.178l-5.834 5.834a.833.833 0 0 1-1.178-1.179l4.41-4.41h-9.654a.833.833 0 1 1 0-1.667h9.654z" fill="#FFF" fill-rule="nonzero" transform="translate(-124 -19)"/></svg>
                                </Fragment>
                            )
                    }
                </button>
            </form>
        )
    }
};

export default LetsTalkForm;
