import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

const ValueBlock = ({right, className, icon, title, description}) => {
    const classes = classnames('value-block', className, {
        'value-block--right': right
    });

    const IconComponent = icon;

    return (
        <div className={classes}>
            <IconComponent/>
            <div className="value-block__title">
                {title}
            </div>
            <div className="value-block__description">
                {description}
            </div>
        </div>
    );
};

ValueBlock.propTypes = {
    className: PropTypes.string,
    right: PropTypes.bool.isRequired,
    icon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default ValueBlock;
